<template>
    <div class="md:max-w-[60%] w-full flex flex-col items-center justify-center gap-8 bg-gradient-to-tr from-orange-400/10 via-indigo-300/10 to-teal-400/10 shadow-lg rounded-box overflow-clip p-6 md:p-12" style="font-family: ShabnamFD, Montserrat;">
        <div class="w-full flex flex-col md:flex-row items-center justify-center gap-16">
            <div class="flex flex-row items-center justify-center w-full h-full">
                <img src="@/assets/img/undraw_contact_us_re_4qqt.svg" class="h-auto w-full object-contain" alt="">
            </div>
            <div class="w-full flex flex-col items-start justify-start gap-4">
                <div class="pointer-events-none w-full flex flex-row items-center justify-center md:justify-end gap-4">
                    <p class="font-bold text-right text-3xl whitespace-nowrap">با ما در تماس باشید</p>
                    <i class="fa-solid fa-headset text-2xl text-success/70"></i>
                </div>
                <p class="pointer-events-none w-full text-justify text-base-content/80 text-lg" dir="rtl">برای سفارشات و مشکلات تکنولوژی خود با ما در تماس باشید.</p>
                <div class="pointer-events-none w-full flex flex-row items-center justify-end gap-2">
                    <p class="w-full text-justify text-base-content/80 text-base" dir="rtl">اصفهان - خیابان حکیم نظامی - خیابان حسین آباد</p>
                    <i class="fa-solid fa-location-dot text-info/70 text-lg"></i>
                </div>
                <div class="w-full grid grid-cols-2 gap-2">
                    <div class="w-full flex flex-row items-center justify-end gap-2">
                        <div class="group relative">
                            <a href="tel:+989133393213" class="text-justify text-base-content/80 text-base whitespace-nowrap" dir="rtl">09133393213</a>
                            <div class="w-0 left-[50%] -bottom-1 absolute h-[2px] bg-slate-400/70 group-hover:w-full group-hover:left-0 transition-all duration-300 ease-in-out"></div>
                        </div>
                        <i class="fa-solid fa-mobile text-slate-400 text-base"></i>
                    </div>
                    <div class="w-full flex flex-row items-center justify-end gap-2">
                        <div class="group relative">
                            <a href="tel:+983136243130" class="text-justify text-base-content/80 text-base whitespace-nowrap" dir="rtl">031-36243130</a>
                            <div class="w-0 left-[50%] -bottom-1 absolute h-[2px] bg-slate-400/70 group-hover:w-full group-hover:left-0 transition-all duration-300 ease-in-out"></div>
                        </div>
                        <i class="fa-solid fa-phone text-slate-400 text-base"></i>
                    </div>
                </div>
                <p class="pointer-events-none w-full text-justify text-base-content/80 text-lg mt-4 font-bold" dir="rtl">ما را در شبکه های اجتماعی دنبال کنید</p>
                <div class="flex flex-row items-center justify-end gap-2 w-full">
                    <!-- instagram -->
                    <button class="btn btn-square btn-ghost text-2xl text-[#ee2a7b]">
                        <i class="fa-brands fa-instagram"></i>
                    </button>
                    <!-- whatsapp -->
                    <button class="btn btn-square btn-ghost text-2xl text-[#00E676]">
                        <i class="fa-brands fa-whatsapp"></i>
                    </button>
                    <!-- email -->
                    <button class="btn btn-square btn-ghost text-2xl text-slate-400">
                        <i class="fa-solid fa-envelope"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="w-full rounded-box overflow-clip h-[350px]">
            <div v-show="!map_is_loaded" class="skeleton w-full h-full z-[2]"></div>
            <iframe @load="map_iframe_loaded" class="w-full h-full z-[1]" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d487.84422071915833!2d51.650029770908425!3d32.625254112714806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3fbc37e80d71b1bf%3A0x3157dd88bd561815!2z2LHYp9uM2KfZhtmHINmH2YXYsdin2Yc!5e0!3m2!1sen!2sde!4v1732519210698!5m2!1sen!2sde" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactSection',
    data() {
        return {
            map_is_loaded: false,
        }
    },
    methods: {
        map_iframe_loaded() {
            setTimeout(() => {
                this.map_is_loaded = true;
            }, 1000);
        }
    }
}
</script>
