<template>
    <!-- add height animation -->
    <div class="group md:max-w-[60%] w-full grid grid-cols-1 md:grid-cols-2 card shadow bg-base-200 p-2 overflow-clip rounded-box gap-8" style="font-family: ShabnamFD, Montserrat;">
        <div class=" w-full h-full rounded-box aspect-square overflow-clip">
            <Transition enterActiveClass="animate__animated animate__zoomIn">
                <img v-show="is_elements_visible" src="@/assets/img/img66.webp" class="group-hover:scale-110 transition-all ease-in-out duration-700 w-full h-full" alt="">
            </Transition>
        </div>
        <div class="flex flex-col items-start justify-between gap-2 p-4">
            <div class="flex flex-col items-start justify-start gap-4">
                <Transition enterActiveClass="animate__animated animate__fadeInRight">
                    <p v-show="is_elements_visible" class="pointer-events-none font-bold w-full text-right text-xl text-base-content">هدف ما</p>
                </Transition>
                <Transition enterActiveClass="animate__animated animate__fadeInRight">
                    <p v-show="is_elements_visible" class="pointer-events-none text-base w-full text-right text-base-content/90 leading-loose" dir="rtl">هدف ما ایجاد اعتماد و انجام پروژه ها و حل مشکلات تکنولوژی کاربران و مشتریانمان می‌باشد، با ما نگران مشکلات تکنولوژی خود نباشید و آنرا به ما بسپارید.</p>
                </Transition>
                <Transition enterActiveClass="animate__animated animate__fadeInLeft">
                    <button v-show="is_elements_visible" class="btn bg-[#9246FF] hover:bg-[#b783ff] text-base-100">مشاهده خدمات</button>
                </Transition>
            </div>
            <Transition v-show="is_elements_visible" enterActiveClass="animate__animated animate__fadeInRight">
                <p class="pointer-events-none text-sm w-full text-right text-base-content/60 mt-16 md:m-0" dir="rtl">محمدجواد یوسفیان - مدیریت رایانه همراه</p>
            </Transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TargetHero',
    data() {
        return {
            is_elements_visible: false,
            is_first_time: true,
        }
    },
    methods: {
        show_elements() {
            if(this.is_first_time)
            {
                setTimeout(() => {
                    this.is_elements_visible = true;
                    this.is_first_time = false;
                }, 500);
            }
        }
    }
}
</script>
