<template>
    <!-- bg-gradient-to-r from-[#CEACD1] to-[#474B9F] -->
    <div class="max-w-[60%] w-full hidden md:grid grid-cols-1 gap-8" style="font-family: ShabnamFD, Montserrat;">
        <div class="flex flex-row items-center justify-center gap-8 w-full">
            <div class="hidden md:flex w-full h-[2px] bg-base-200 dark:bg-base-content/30 rounded-full"></div>
            <div class="pointer-events-none font-bold text-3xl text-base-content whitespace-nowrap">وردپرس</div>
            <div class="hidden md:flex w-full h-[2px] bg-base-200 dark:bg-base-content/30 rounded-full"></div>
        </div>
        <div class="bg-base-100 min-h-[200px] card p-8 border border-base-200 rounded-box shadow gap-8 overflow-clip grid grid-cols-1 w-full">
            <div class="flex flex-row items-center justify-between gap-2 w-full">
                <Transition enterActiveClass="animate__animated animate__fadeInLeft">
                    <img v-show="is_elements_visible" class="dark:hidden w-auto max-h-8 h-full" src="@/assets/img/e-company.svg">
                </Transition>
                <Transition enterActiveClass="animate__animated animate__fadeInLeft">
                    <img v-show="is_elements_visible" class="hidden dark:inline-block w-auto max-h-8 h-full" src="@/assets/img/e-company-light.svg">
                </Transition>
                <div class="flex flex-row-reverse items-center justify-start gap-4 w-full font-bold text-base pointer-events-none">
                    <Transition enterActiveClass="animate__animated animate__fadeInRight">
                        <i v-show="is_elements_visible" class="fa-solid fa-bars text-xl"></i>
                    </Transition>
                    <Transition enterActiveClass="animate__animated animate__fadeInRight">
                        <p v-show="is_elements_visible">خانه</p>
                    </Transition>
                    <Transition enterActiveClass="animate__animated animate__fadeInRight">
                        <p v-show="is_elements_visible">فروشگاه</p>
                    </Transition>
                    <Transition enterActiveClass="animate__animated animate__fadeInRight">
                        <p v-show="is_elements_visible">درباره ما</p>
                    </Transition>
                    <Transition enterActiveClass="animate__animated animate__fadeInRight">
                        <p v-show="is_elements_visible">تماس با ما</p>
                    </Transition>
                </div>
            </div>
            <div class="relative flex flex-row items-center justify-end w-full">
                <Transition enterActiveClass="animate__animated animate__fadeInUp">                    
                    <img v-show="is_elements_visible" class="max-w-[60%] pointer-events-none w-full object-contain h-auto rounded-box overflow-clip" src="@/assets/img/export-mobile-wordpress-section.svg">
                </Transition>
                <div class="absolute top-[50%] translate-y-[-50%] right-[60%] max-w-[70%] flex flex-col items-end justify-center gap-2">
                    <Transition enterActiveClass="animate__animated animate__fadeInLeft">
                        <p v-show="is_elements_visible" class="pointer-events-none font-bold text-4xl whitespace-nowrap">فروشگاه آنلاین</p>
                    </Transition>
                    <Transition enterActiveClass="animate__animated animate__fadeInLeft">
                        <p v-show="is_elements_visible" class="pointer-events-none font-bold text-lg text-base-content/60 whitespace-nowrap">پروژه های <span class="text-[#9246ff]">وردپرس</span> خود را به ما بسپارید</p>
                    </Transition>
                    <Transition enterActiveClass="animate__animated animate__fadeInLeft">
                        <p v-show="is_elements_visible" dir="rtl" class="mt-8 pointer-events-none font-bold text-sm text-base-content/60 w-full text-justify">صفر تا صد پروژه های فروشگاهی و دیگر پروژه های وردپرسی خود را به ما بسپارید و دیگر نگران مشکلات فنی و اجرای پروژه‌ی خود نباشید.</p>
                    </Transition>
                    <div class="w-full flex flex-row items-center justify-start mt-8">
                        <Transition enterActiveClass="animate__animated animate__backInUp">
                            <button v-show="is_elements_visible" class="btn pointer-events-none text-sm text-base-100 rounded-full bg-gradient-to-r hover:bg-gradient-to-r from-[#CEACD1] to-[#474B9F] hover:from-[#d4bbd6] hover:to-[#565bb6]">
                                اکنون خرید کنید
                            </button>
                        </Transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WordpressPreviewComp',
    data() {
        return {
            is_elements_visible: false,
            is_first_time: true,
        }
    },
    methods: {
        show_elements() {
            if(this.is_first_time)
            {
                this.is_elements_visible = true;
                this.is_first_time = false;
            }
        }
    }
}
</script>

<style>
.imageSvgAnim {
    fill:transparent;
    stroke: #fff;
    stroke-width: 0.1;
    stroke-dasharray: 50;
    stroke-dashoffset: 50;
    animation: imageSvgAnimation 10s ease-in-out;
}

@keyframes imageSvgAnimation {
    0% {
        stroke-dashoffset: 50;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
</style>
