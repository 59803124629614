<template>
    <div class="md:max-w-[60%] w-full grid grid-cols-1 gap-4" style="font-family: ShabnamFD, Montserrat;">
        <div class="flex flex-row items-center justify-center gap-8 w-full">
            <div class="hidden md:flex w-full h-[2px] bg-base-200 dark:bg-base-content/30 rounded-full"></div>
            <div class="pointer-events-none font-bold text-3xl text-base-content whitespace-nowrap">برخی از محصولات ما</div>
            <div class="hidden md:flex w-full h-[2px] bg-base-200 dark:bg-base-content/30 rounded-full"></div>
        </div>
        <div class="w-full grid grid-cols-1 md:grid-cols-3 gap-4">
            <!-- card 1 -->
            <div class="group bg-gradient-to-br from-purple-400 to-blue-200 via-indigo-300 card rounded-box overflow-clip shadow-[#9246FF]/30 shadow-lg border p-8 w-full h-[400px] relative">
                <div class="flex flex-col items-center justify-start gap-2">
                    <p class="pointer-events-none text-base-content dark:text-base-100 font-bold text-3xl w-full text-left">Apple</p>
                    <p class="pointer-events-none text-base-content dark:text-base-100 font-bold text-3xl w-full text-left">Airpod Max</p>
                    <button class="btn text-base-content/70 dark:text-base-100/70 btn-sm mt-4 bg-base-100/30 hover:bg-base-100/40 backdrop-blur-sm border-none flex flex-row items-center justify-center gap-2">
                        <i class="fa-light fa-arrow-left"></i>
                        <p>بیشتر بخوانید</p>
                    </button>
                    <img class="group-hover:scale-110 transition-all ease-in-out duration-700 absolute bottom-[-10%] object-contain w-[90%]" src="@/assets/img/products/apple-airdpod-max.png" alt="">
                </div>
            </div>
            <!-- card 2 -->
            <div class="group bg-gradient-to-tr from-yellow-300 to-orange-400 card rounded-box overflow-clip shadow-[#9246FF]/30 shadow-lg border p-8 w-full h-[400px] relative">
                <div class="flex flex-col items-center justify-end gap-2 h-full">
                    <p class="pointer-events-none text-base-content dark:text-base-100 font-bold text-3xl w-full text-left">Apple</p>
                    <p class="pointer-events-none text-base-content dark:text-base-100 font-bold text-3xl w-full text-left">Airpod Pro</p>
                    <button class="btn text-base-content/70 dark:text-base-100/70 btn-sm mt-4 bg-base-100/30 hover:bg-base-100/40 backdrop-blur-sm border-none flex flex-row items-center justify-center gap-2">
                        <i class="fa-light fa-arrow-left"></i>
                        <p>بیشتر بخوانید</p>
                    </button>
                    <img class="group-hover:scale-110 transition-all ease-in-out duration-700 absolute top-[5%] object-contain w-[70%]" src="@/assets/img/products/apple-airpod-pro.png" alt="">
                </div>
            </div>
            <!-- card 3 -->
            <div class="group bg-gradient-to-r from-emerald-400 to-cyan-500 card rounded-box overflow-clip shadow-[#9246FF]/30 shadow-lg border p-8 w-full h-[400px] relative">
                <div class="flex flex-col items-center justify-start gap-2">
                    <p class="pointer-events-none text-base-content dark:text-base-100 font-bold text-3xl w-full text-left">Razer</p>
                    <p class="pointer-events-none text-base-content dark:text-base-100 font-bold text-3xl w-full text-left">Gaming Mouse</p>
                    <button class="btn text-base-content/70 dark:text-base-100/70 btn-sm mt-4 bg-base-100/30 hover:bg-base-100/40 backdrop-blur-sm border-none flex flex-row items-center justify-center gap-2">
                        <i class="fa-light fa-arrow-left"></i>
                        <p>بیشتر بخوانید</p>
                    </button>
                    <img class="group-hover:scale-110 transition-all ease-in-out duration-700 absolute bottom-[-10%] right-5 w-[52%]" src="@/assets/img/products/custom-design-6.webp" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductsListPreview',
}
</script>
