<template>
    <div class="md:max-w-[60%] w-full grid grid-cols-1 md:grid-cols-2 gap-8" style="font-family: ShabnamFD, Montserrat;">
        <!-- text -->
        <Transition appear enterActiveClass="animate__animated animate__fadeInLeft">
            <div class="flex flex-col items-center justify-center gap-4 pointer-events-none">
                <p class="font-bold text-[3rem] w-full text-base-content text-center leading-extra-loose pointer-events-none">به <span class="text-[#9246FF]" dir="rtl">رایانه همراه</span> خوش آمدید</p>
                <p class="text-[1rem] w-full text-base-content/60 text-center pointer-events-none" dir="rtl">-  با ما بهترین را تجربه کنید  -</p>
            </div>
        </Transition>
        <!-- cards -->
        <div class="w-full grid grid-cols-2 gap-3">
            <div class="w-full grid grid-rows-2 gap-3 mt-8">
                <Transition appear enterActiveClass="animate__animated animate__fadeInLeft">
                    <div class="relative shadow-md shadow-[#9246FF]/30 w-full h-full aspect-square rounded-box overflow-clip bg-base-200 group cursor-pointer">
                        <img class="absolute w-full h-full object-cover opacity-100 group-hover:opacity-0 transition-all duration-500" src="@/assets/img/gaming-system.webp" alt="">
                        <img class="absolute w-full h-full object-cover opacity-0 group-hover:opacity-100 group-hover:scale-125 transition-all duration-1000" src="@/assets/img/cool-computer-setup.webp" alt="">
                    </div>
                </Transition>
                <Transition appear enterActiveClass="animate__animated animate__fadeInLeft">
                    <div class="shadow-md shadow-[#9246FF]/30 w-full h-full aspect-square rounded-box pointer-events-none overflow-clip bg-base-200/85 flex flex-col items-end justify-end gap-1 p-4">
                        <p class="text-4xl font-bold text-base-content">20+</p>
                        <p class="text-xl font-semibold text-base-content/70">تجربه بالا</p>
                    </div>
                </Transition>
            </div>
            <div class="w-full grid grid-rows-2 gap-3 mb-8">
                <Transition appear enterActiveClass="animate__animated animate__fadeInRight">
                    <div class="shadow-md shadow-[#9246FF]/30 w-full h-full aspect-square rounded-box pointer-events-none overflow-clip bg-base-200/85 flex flex-col items-end justify-end gap-1 p-4">
                        <p class="text-4xl font-bold text-base-content">20+</p>
                        <p class="text-xl font-semibold text-base-content/70">سابقه بالا</p>
                    </div>
                </Transition>
                <Transition appear enterActiveClass="animate__animated animate__fadeInRight">
                    <div class="relative shadow-md shadow-[#9246FF]/30 w-full h-full aspect-square rounded-box overflow-clip bg-base-200 group cursor-pointer">
                        <img class="absolute w-full h-full object-cover opacity-100 group-hover:opacity-0 transition-all duration-500" src="@/assets/img/cool-keyboard.webp" alt="">
                        <img class="absolute w-full h-full object-cover opacity-0 group-hover:opacity-100 group-hover:scale-125 transition-all duration-1000" src="@/assets/img/cool-gpu.webp" alt="">
                    </div>
                </Transition>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FourCardsComp',
}
</script>