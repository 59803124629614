<template>
    <div class="flex flex-col items-center justify-start bg-base-100 relative w-full p-6 gap-6 overflow-x-hidden">
        <!-- gradient bg -->
        <img class="w-full max-h-[300px] h-full object-cover absolute top-0" src="@/assets/img/bg-gradient.png">
        <!-- navbar -->
        <Transition enterActiveClass="animate__animated animate__fadeInDown" appear>
            <Navbar />
        </Transition>
        <!-- carousel -->
        <Carousel />
        <!-- 4 cards comp -->
        <FourCardsComp class="mt-6 md:mt-12 mb-12" />
        <!-- wordpress section -->
        <WordpressPreviewComp id="wordpress_elm" ref="wordpress_elm_ref" />
        <!-- target hero -->
        <TargetHero class="mt-12" id="target_hero_elm" ref="target_hero_elm_ref" />
        <!-- products list -->
        <ProductsListPreview class="mt-12" />
        <!-- our-team -->
        <OutTeam class="mt-12" />
        <!-- infinite comp -->
        <FadeInfiniteScrollComp class="mt-12" />
        <!-- contact section -->
        <ContactSection class="mt-12" />
        <!-- footer bg -->
        <img class="w-full max-h-[300px] h-full object-cover absolute bottom-0 rotate-180" src="@/assets/img/bg-gradient.png">
        <!-- footer -->
        <Footer class="mt-16" />
    </div>
</template>

<script>
import Navbar from '@/components/navbar.vue';
import Carousel from '@/components/carousel.vue';
import FourCardsComp from '@/components/four-cards-comp.vue';
import TargetHero from '@/components/target-hero.vue';
import OutTeam from '@/components/our-team';
import ProductsListPreview from '@/components/products-list-preview.vue';
import ContactSection from '@/components/contact-section.vue';
import Footer from '@/components/footer.vue';
import FadeInfiniteScrollComp from '@/components/fade-infinite-scroll-comp.vue';
import WordpressPreviewComp from '@/components/wordpress-preview-comp';
import { useElementVisibility } from '@vueuse/core';

export default {
    name: 'Index',
    components: {
        Navbar,
        FourCardsComp,
        Carousel,
        TargetHero,
        OutTeam,
        ProductsListPreview,
        ContactSection,
        Footer,
        FadeInfiniteScrollComp,
        WordpressPreviewComp,
    },
    data() {
        return {
            wordpress_visiblity: false,
            target_hero_visiblity: false,
        }
    },
    mounted() {
        this.awake();
    },
    methods: {
        awake() {
            this.scrollToTop();

            if(document.getElementById('wordpress_elm'))
                this.wordpress_visiblity = useElementVisibility(document.getElementById('wordpress_elm'));

            if(document.getElementById('target_hero_elm'))
                this.target_hero_visiblity = useElementVisibility(document.getElementById('target_hero_elm'));

            document.getElementById('wrapper').addEventListener('scroll', ()=>{
                if(this.wordpress_visiblity)
                    this.$refs.wordpress_elm_ref.show_elements();

                if(this.target_hero_visiblity)
                    this.$refs.target_hero_elm_ref.show_elements();
            });
        },
        // scroll page to top
        scrollToTop() {
            if(document.getElementById('wrapper'))
                document.getElementById('wrapper').scrollTo({ top: 0, behavior: 'smooth' });
        },
    }
}
</script>
