import { createRouter, createWebHistory } from 'vue-router'
import Index from '@/views/Index.vue';
import PageNotFound from '@/views/PageNotFound.vue';

const routes = [
    {
        path: '/',
        name: 'index',
        component: Index
    },
    {
        path: '/:catchAll(.*)*',
        name: "PageNotFound",
        component: PageNotFound,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
