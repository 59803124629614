<template>
  <div id="wrapper" class="main-view overflow-x-hidden overflow-y-auto print:overflow-y-hidden" :data-theme="this.$store.state.app_theme"
      :class="{ 'dark': this.$store.state.use_dark }">
      <router-view />
      <LoadingComponent />
      <ToastComponent />
  </div>
</template>

<script>
import LoadingComponent from '@/components/loading-component.vue';
import ToastComponent from '@/components/toast-component.vue';

export default {
  name: 'app',
  mounted() {
      this.awake();
  },
  components: {
      LoadingComponent,
      ToastComponent,
  },
  methods: {
      awake() {
          // set document title
          document.title = this.$store.state.app_title;

          // app theme
          let app_theme = localStorage.getItem(this.$store.state.app_name + '_app_theme');
          let use_dark = localStorage.getItem(this.$store.state.app_name + '_use_dark');
          if (app_theme && this.$store.state.themes_list.includes(app_theme) && use_dark && (use_dark == 'true' || use_dark == 'false')) {
              this.$store.state.app_theme = app_theme;
              this.$store.state.use_dark = (use_dark == 'true' ? true : false);
          }
      },
  },
}
</script>
