<template>
    <div class="fixed z-[999999] bottom-3 w-full justify-center flex flex-col items-center gap-3 pointer-events-none" dir="rtl" v-if="this.$store.state.toast_items.length > 0">
        <template v-for="(item, index) in this.$store.state.toast_items" :key="index">
            <ToastItem :toast-id="item.id" />
        </template>
    </div>
</template>

<script>
import ToastItem from '@/components/toast-item.vue';

export default {
    name: 'ToastComponent',
    components: {
        ToastItem,
    },
}
</script>
